import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import './css/Style.css';
import Topo from './components/Topo';
import Banner from './components/Banner';
import VivendasBosque from './components/VivendasBosque';
import GaleriaFotos from './components/GaleriaFotos';
import Videos from './components/Videos';
import Localizacao from './components/Localizacao';
import Prazo from './components/Prazo';
import Contato from './components/Contato';
import Icones from './components/Icones';
import Footer from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Topo />
    <Banner />
    <LazyLoad once height={200} offset={100}>
      <VivendasBosque />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <GaleriaFotos />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Videos />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Localizacao />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Prazo />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Contato />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Icones />
    </LazyLoad>
    <LazyLoad once height={200} offset={100}>
      <Footer />
    </LazyLoad>
  </BrowserRouter>
);
