import GlassZoom from 'react-glass-zoom';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import mapaWebp from '../imagens/foldervivendasparaapresentacao.webp';
import mapaPng from '../imagens/foldervivendasparaapresentacao.png';

function Prazo() {
    
    return (
        <div className="max-w-7xl mx-auto pb-10" id="entrega">
            <p className="text-secondary text-3xl pt-32 mb-12 text-center">
                PRAZO DE ENTREGA<br />SETEMBRO 2027
            </p>

            <p className='text-center text-3xl text-white w-48 mb-5 sm:-mb-16 ml-28'>Apenas<br />
            188 unidades</p>

            <GlassZoom>
                <img
                    src={isWebpSupported() ?  mapaWebp : mapaPng}
                    alt='Mapa Vivendas do Bosque'
                    magnifierHeight={200}
                    magnifieWidth={200}
                    zoomLevel={4}
                />
            </GlassZoom>
        </div>
    );
}

export default Prazo;
