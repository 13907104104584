import Image from 'react-image-webp';


function VivendasBosque() {
    return (
        <>
            <div className="max-w-7xl mx-auto grid grid-cols-12 mt-10 mb-5 pt-32" id='o-vivendas-do-bosque'>
                <div className="col-span-12 md:col-span-6 bg-terciary text-primary px-10 py-10">
                    <Image
                        src={require('../imagens/Camada_x0020_1.png')}
                        webp={require('../imagens/Camada_x0020_1.webp')}
                        alt='Vivendas do Bosque'
                    />
                    <p className="text-3xl font-semibold mb-3 mt-10">O VIVENDAS DO BOSQUE</p>
                    <p>é um verdadeiro refúgio que desprende você da rotina incessante de uma cidade que não para, permitindo que sua família vivencie a melhor experiência em meio a uma vegetação nativa, perfeita para recarregar as energias e criar os ﬁlhos com mais qualidade de vida.</p>
                </div>
                <div className="col-span-12 md:col-span-6">
                    <Image
                        src={require('../imagens/VIVENDAS 02.png')}
                        webp={require('../imagens/VIVENDAS 02.webp')}
                        alt='Vivendas do Bosque - Fachada'
                    />
                </div>
            </div>
        </>
    );
}

export default VivendasBosque;
