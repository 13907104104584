import { Link } from "react-router-dom";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import Foto from "../imagens/Group 14.png";
import FotoWeb from "../imagens/Group 14.webp";


function Banner() {
    return (
        <>
            <div className={ isWebpSupported() ? "banner-webp flex w-full flex-col justify-center align-middle text-center" : "banner flex w-full flex-col justify-center align-middle text-center"}>
                <p className='text-secondary text-3xl sm:text-4xl mb-2'>Bem-vindo(a) ao Vivendas do Bosque.<br />Seu condomínio de chácaras em Cascavel</p>
                <p className='text-white mb-4 text-sm sm:text-base'>Descubra o condomínio dos seus sonhos e desfrute de uma <br />experiência exclusiva que inspira o sentimento de lar.</p>
                <Link to="https://wa.me/5545991138811?text=Vim%20do%20website%20e%20gostaria%20de%20agendar%20uma%20visita." target="_blank" className="text-sm sm:text-base text-primary bg-secondary px-8 py-2 sm:py-0 rounded-md  mx-auto">
                    TENHO INTERESSE EM AGENDAR UMA VISITA!
                </Link>
            </div>
            <div className='max-w-7xl mx-4 sm:mx-auto flex flex-row flex-wrap justify-around border border-secondary rounded-md px-6 py-6 -mt-16'>
                <p className='text-white text-center'>
                    <span className='text-secondary text-3xl sm:text-5xl'>65 mil</span><br />
                    m² de área de lazer
                </p>
                <p className='text-white text-center text-base'>
                    <span className='text-secondary text-3xl sm:text-4xl'>Matrículas</span><br />
                    Indivudualizadas
                </p>
                <p className='text-white text-center text-base mt-4 sm:mt-0'>
                    <span className='text-secondary text-2xl sm:text-4xl'>Acesso fácil</span><br />
                    E SEGURO
                </p>
                <p className='text-white text-center text-base mt-4 sm:mt-0'>
                    <span className='text-secondary text-3xl sm:text-5xl'>1.000 m²</span><br />
                    TERRENOS A PARTIR
                </p>
            </div>
            <div className="max-w-7xl mx-auto grid grid-cols-12 mt-10 mb-5">
                <div className="col-span-12 mx-4 sm:ms-0 mb-4 sm:mb-0 md:col-span-6 lg:col-span-4 flex flex-row justify-end">
                    <img
                        alt="Vivendas do Bosque"
                        src={isWebpSupported() ? FotoWeb : Foto }
                        className="foto-efeito"
                    />
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-8 pl-10 text-white">
                    <p className="text-3xl text-secondary font-semibold mb-3">TRANQUILIDADE</p>
                    <p className="mb-6">Aqui, a tranquilidade de poder ouvir a sinfonia dos pássaros e sentir em cada canto do seu corpo o ar fresco do campo, se une às diversas opções de lazer que estão à sua disposição sempre que quiser.</p>
                    <p className="text-3xl text-secondary font-semibold mb-3">LIBERDADE</p>
                    <p>Com terrenos a partir de 1.000 m² e matrículas individualizadas, o condomínio de chácaras oferece uma amplitude única, permitindo a você ter mais liberdade de explorar todas as possibilidades e a criatividade na hora de construir.</p>
                </div>
            </div>
        </>
    );
}

export default Banner;
