import { useState, memo } from 'react';
import { HashLink } from 'react-router-hash-link';
import logo from "../imagens/logo.svg";
import whatsApp from "../imagens/whatsapp_icon.png";

function Topo() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <>
            <header className='fixed z-10 w-full bg-topo' >
                <div className="h-8 text-white text-center pt-2 sm:pt-1 bg-[#257B5E] text-[11px] md:text-base "><span className="text-secondary">VOCÊ MERECE O MELHOR&nbsp;</span> EM CADA ASPECTO DA SUA VIDA!</div>
                <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
                    <div className="flex lg:flex-1">
                        <HashLink to="#top" smooth className="flex flex-row align-middle">
                            {/* <span className="sr-only">Vivendas do Bosque</span> */}
                            <img
                                alt="Vivendas do Bosque"
                                src={logo}
                                className="h-auto w-40"
                            />
                        </HashLink>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            ariaLabel="Botão menu"
                            role='none'
                            type="button"
                            onClick={() => setMobileMenuOpen(true)}
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        >
                            <svg className="h-6 w-6 text-white" fill="#fff" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-5">
                        <HashLink to="#top" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'top' || window.location.hash.slice(1) === '') ? 'text-secondary' : 'text-white'}>
                            HOME
                        </HashLink>
                        <HashLink to="#o-vivendas-do-bosque" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'o-vivendas-do-bosque') ? 'text-secondary' : 'text-white'}>
                            O VIVENDAS DO BOSQUE
                        </HashLink>
                        <HashLink to="#fotos" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'fotos') ? 'text-secondary' : 'text-white'}>
                            FOTOS
                        </HashLink>
                        <HashLink to="#videos" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'seu-refugio') ? 'text-secondary' : 'text-white'}>
                            SEU REFÚGIO
                        </HashLink>
                        <HashLink to="#entrega" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'entrega') ? 'text-secondary' : 'text-white'}>
                            ENTREGA
                        </HashLink>
                        <HashLink to="#localizacao" smooth className={"text-sm/6 font-semibold hover:text-secondary" + (window.location.hash.slice(1) === 'localizacao') ? 'text-secondary' : 'text-white'}>
                            LOCALIZAÇÃO
                        </HashLink>
                    </div>
                    <div className="hidden xl:flex xl:flex-1 xl:justify-end">
                        <HashLink to="#cadastre" smooth className="text-sm/6  text-primary bg-secondary px-8 rounded-md">
                            Cadastre-se
                        </HashLink>
                    </div>
                </nav>
                <div className={`lg:hidden ${mobileMenuOpen ? '' : 'hidden'}`}>
                    <div className="fixed inset-0 z-10" />
                    <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-primary px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <HashLink to="#top" smooth className="flex flex-row align-middle">
                                <span className="sr-only">Vivendas do Bosque</span>
                                <img
                                    alt="Vivendas do Bosque"
                                    src={logo}
                                    className="h-auto w-40"
                                />
                            </HashLink>
                            <button
                                type="button"
                                onClick={() => setMobileMenuOpen(false)}
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            >
                                <svg className="h-6 w-6 text-white" fill="#FFFFFF" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>

                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-white">
                                <div className="space-y-2 py-6 flex flex-col">
                                    <HashLink to="#top" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        HOME
                                    </HashLink>
                                    <HashLink to="#o-vivendas-do-bosque" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        O VIVENDAS DO BOSQUE
                                    </HashLink>
                                    <HashLink to="#fotos" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        FOTOS
                                    </HashLink>
                                    <HashLink to="#videos" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        SEU REFÚGIO
                                    </HashLink>
                                    <HashLink to="#entrega" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        ENTREGA
                                    </HashLink>
                                    <HashLink to="#localizacao" smooth className="text-sm/6 hover:text-secondary text-white" onClick={() => setMobileMenuOpen(false)}>
                                        LOCALIZAÇÃO
                                    </HashLink>
                                </div>
                                <div className="py-6">
                                    <HashLink to="#cadastre" smooth className="text-sm/6  text-primary bg-secondary px-8 py-2 rounded-md" onClick={() => setMobileMenuOpen(false)}>
                                        Cadastre-se
                                    </HashLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <a href="https://wa.me/5545991138811?text=Vim%20do%20website%20e%20gostaria%20de%20agendar%20uma%20visita." target="_blank" rel="noreferrer">
                <div className="btn-whatsapp pulsaDelay">
                    <img
                        alt="Whatsapp icone"
                        src={whatsApp}
                        className="h-auto w-20"
                    />
                </div>
            </a>
        </>
    );
}

export default memo(Topo);
