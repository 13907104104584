import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Icones() {
    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        0: { items: 1 },
        568: { items: 3 },
        1024: { items: 6 },
    }

    const items = [
        <div className="item text-center" data-value="Guarita 24h">
            <img className='mx-auto' src="./imagens/icon/1.png" width="50" alt='Icone Guarita 24h' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Guarita 24h</span>
        </div>,
        <div className="item text-center" data-value="Mini Fazenda">
            <img className='mx-auto' src="./imagens/icon/2.png" width="50" alt='Icone Mini Fazenda' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Mini Fazenda</span>
        </div>,
        <div className="item text-center" data-value="Vestiário">
            <img className='mx-auto' src="./imagens/icon/3.png" width="50" alt='Icone Vestiário' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Vestiário</span>
        </div>,
        <div className="item text-center" data-value="Quiosques">
            <img className='mx-auto' src="./imagens/icon/4.png" width="50" alt='Icone Quiosques' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Quiosques</span>
        </div>,
        <div className="item text-center" data-value="Salão de Festas">
            <img className='mx-auto' src="./imagens/icon/5.png" width="50" alt='Icone Salão de Festas' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Salão de Festas</span>
        </div>,
        <div className="item text-center" data-value="Ponto de Recarga">
            <img className='mx-auto' src="./imagens/icon/6.png" width="50" alt='Icone Ponto de Recarga' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Ponto de Recarga</span>
        </div>,
        <div className="item text-center" data-value="Sala de Jogos">
            <img className='mx-auto' src="./imagens/icon/7.png" width="50" alt='Icone Sala de Jogos' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Sala de Jogos</span>
        </div>,
        <div className="item text-center" data-value="Coworking">
            <img className='mx-auto' src="./imagens/icon/8.png" width="50" alt='Icone Coworking' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Coworking</span>
        </div>,
        <div className="item text-center" data-value="Cinema">
            <img className='mx-auto' src="./imagens/icon/9.png" width="50" alt='Icone Cinema' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Cinema</span>
        </div>,
        <div className="item text-center" data-value="Sauna">
            <img className='mx-auto' src="./imagens/icon/10.png" width="50" alt='Icone Sauna' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Sauna</span>
        </div>,
        <div className="item text-center" data-value="Quadras Esportivas">
            <img className='mx-auto' src="./imagens/icon/11.png" width="50" alt='Icone Quadras Esportivas' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Quadras Esportivas</span>
        </div>,
        <div className="item text-center" data-value="Capela Ecumênica">
            <img className='mx-auto' src="./imagens/icon/12.png" width="50" alt='Icone Capela Ecumênica' onDragStart={handleDragStart} role="presentation" />
            <span className='text-white'>Capela Ecumênica</span>
        </div>,
    ];

    return (
        <div className="max-w-7xl mx-auto pb-10 pt-20" id="icones">
            <h1 className='text-3xl mb-10 text-white'>Descubra as&nbsp;<span className='bg-gradient-to-r from-secondary to-[#FF9100]'>&nbsp;exclusividades&nbsp;</span>&nbsp;do Condomínio Vivendas do Bosque!</h1>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                autoPlay={true}
                infinite={true}
                autoPlayInterval={1000}
                controlsStrategy="alternate"
                disableDotsControls
                disableButtonsControls
            />
        </div>
    );
}

export default Icones;
