import logo from "../imagens/logo.svg";

function Footer() {
    return (
        <>
           

            <div className="max-w-7xl mx-auto bg-[rgba(25,41,36,0.8)] pt-10">

                <div className="grid grid-cols-12 pb-10">
                    <div className="col-span-12 md:col-span-6 pl-14 mb-10 sm:mb-0">
                        <img
                            alt="Vivendas do Bosque"
                            src={logo}
                            className="h-auto w-56"
                        />
                    </div>
                    <div className="col-span-12 md:col-span-6 sm:pr-14">
                        <a href='https://maps.app.goo.gl/Ptzyi8qEm2K2GXaL9' target='_blank' rel="noreferrer" smooth className='flex flex-row justify-center sm:justify-end '>
                            <p className="text-sm/6  text-white bg-secondary px-8 py-2 rounded-md flex flex-row w-96 justify-center">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.5 0.485352C9.4796 0.485352 7.03125 3.01102 7.03125 6.1268C7.03125 8.64575 10.6055 13.2368 11.9748 14.9033C12.2522 15.2409 12.7483 15.2409 13.0252 14.9033C14.3945 13.2368 17.9688 8.64575 17.9688 6.1268C17.9688 3.01102 15.5204 0.485352 12.5 0.485352ZM12.5 8.00728C11.4931 8.00728 10.6771 7.16554 10.6771 6.1268C10.6771 5.08806 11.4931 4.24632 12.5 4.24632C13.5069 4.24632 14.3229 5.08806 14.3229 6.1268C14.3229 7.16554 13.5069 8.00728 12.5 8.00728ZM0.873264 10.1542C0.615529 10.2605 0.394588 10.4441 0.238936 10.6812C0.083285 10.9183 6.17278e-05 11.198 0 11.4844L0 22.6921C0 23.1989 0.496094 23.5454 0.952257 23.3574L6.94444 20.5438V10.108C6.56076 9.39257 6.24696 8.6959 6.02214 8.02967L0.873264 10.1542ZM12.5 16.589C11.8893 16.589 11.3116 16.3123 10.9154 15.8296C10.0621 14.7909 9.15451 13.608 8.33333 12.3946V20.5434L16.6667 23.4089V12.3951C15.8455 13.608 14.9384 14.7913 14.0846 15.8301C13.6884 16.3123 13.1107 16.589 12.5 16.589ZM24.0477 7.70103L18.0556 10.5146V23.4093L24.1267 20.9043C24.3845 20.798 24.6055 20.6144 24.7611 20.3773C24.9168 20.1402 25 19.8604 25 19.574V8.36636C25 7.85953 24.5039 7.51298 24.0477 7.70103Z" fill="white" />
                                </svg>
                                &nbsp;
                                Como chegar no vivendas do bosque</p>
                        </a>
                    </div>
                </div>
                <div className="grid grid-cols-12 pb-2">
                    <div className="col-span-12 md:col-span-6 text-center sm:text-left sm:pl-14 text-xs text-white mb-4 sm:mb-0">
                        Copyright Vivendas do Bosque - Todos os Direitos Reservados.
                    </div>
                    <div className="col-span-12 md:col-span-6 sm:pr-14 text-xs text-white text-center sm:text-right">
                        Desenvolvido por V6 Desenvolvimento
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;
