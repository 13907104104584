import { useState } from 'react';
import Image from 'react-image-webp';
import emailjs from '@emailjs/browser';
import { useMask } from '@react-input/mask';
import '../css/formulario.css';

const options = {
    mask: '+55 (__) _____-____',
    replacement: { _: /\d/ },
};


function Contato() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");

    const inputRef = useMask(options);

    function sendEmail(data, e) {
        e.preventDefault();

        if (name === '' || email === '' || telefone === '') {
            alert('Preencha todos os campos!');
            return;
        }
        const emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
        if (!emailPattern.test(email)) {
            alert('O e-mail precisa ser válido!');
            return;
        }

        const templateParams = {
            from_name: name,
            email: email,
            telefone: telefone
        }

        emailjs.send("service_pnn1hi4", "template_wi8if1e", templateParams, "XOuM3DCYUeUoZAgk5")
            .then((response) => {
                console.log('email enviado', response.status, response.text)
                setName('')
                setEmail('')
                setTelefone('')
            }, (error) => {
                console.log("ERROR: ", error)
            });
    }

    return (
        <>
            <div className="max-w-7xl mx-auto grid grid-cols-12 " id='cadastre'>
                <div className="col-span-12 md:col-span-6">
                    <Image
                        src={require('../imagens/VIVENDAS 02.png')}
                        webp={require('../imagens/VIVENDAS 02.webp')}
                    />
                </div>
                <div className="col-span-12 md:col-span-6 bg-terciary text-primary px-10 py-10">
                    <p className="text-3xl font-semibold mb-10 mt-10">CONTATO</p>
                    <form>
                        <div className='form'>
                            <input
                                type="text"
                                placeholder='Nome'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className='form'>
                            <input
                                type="text"
                                placeholder='E-mail'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='form'>
                            <input
                                type="text"
                                placeholder='Telefone'
                                ref={inputRef}
                                onChange={(e) => setTelefone(e.target.value)}
                            />
                        </div>
                        <div className='flex flex-row justify-end h-10'>
                            <input className="text-sm/6  text-primary bg-secondary px-8 rounded-xl right-0" type="submit" value="Enviar" onClick={sendEmail} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Contato;
