import YouTube from 'react-youtube';

function Videos() {
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
            controls: 0
        },
    };
    return (
        <div className="max-w-7xl mx-auto bg-terciary pb-10 pt-20" id="videos">
            <p className="text-primary text-4xl pt-14 mb-12 text-center">Seu <b>futuro</b> refúgio <br />em <b>Cascavel</b></p>
            <div className='w-full flex flex-col justify-center'>
                <div className='video-container'><YouTube videoId="3zO3ZE8TUoY" opts={opts} className='mx-auto' /></div>
            </div>
        </div>
    );
}

export default Videos;
