import { Slide } from "react-slideshow-image";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import "react-slideshow-image/dist/styles.css";

function GaleriaFotos() {
    const imagesWebp = [
        "../imagens/banner/image2024-10-21at15.46.57.webp",
        "../imagens/banner/image2024-10-21at15.46.58.webp",
        "../imagens/banner/image2024-10-21at15.46.581.webp",
        "../imagens/banner/image2024-10-21at15.46.59.webp",
        "../imagens/banner/image2024-10-21at15.46.591.webp",
        "../imagens/banner/image2024-10-21at15.46.592.webp",
        "../imagens/banner/image2024-10-21at15.47.00.webp"
    ];

    const imagesPng = [
        "../imagens/banner/image2024-10-21at15.46.57.jpeg",
        "../imagens/banner/image2024-10-21at15.46.58.jpeg",
        "../imagens/banner/image2024-10-21at15.46.581.jpeg",
        "../imagens/banner/image2024-10-21at15.46.59.jpeg",
        "../imagens/banner/image2024-10-21at15.46.591.jpeg",
        "../imagens/banner/image2024-10-21at15.46.592.jpeg",
        "../imagens/banner/image2024-10-21at15.47.00.jpeg"
    ];

    return (
        <div className="max-w-7xl mx-auto mb-28" id="fotos">
            <p className="text-3xl font-semibold mb-12 mt-16 pt-32 text-secondary">Fotos do empreendimento</p>
            <Slide easing="ease">
                {
                    isWebpSupported()
                        ? imagesWebp.map((slide, index) => {
                            return (
                                <div className="slide" key={slide}>
                                    <div style={{ backgroundImage: `url(${imagesWebp[index]})` }}>
                                        {/* <span>Slide {index + 1}</span> */}
                                    </div>
                                </div>
                            );
                        })
                        : imagesPng.map((slide, index) => {
                            return (
                                <div className="slide" key={slide}>
                                    <div style={{ backgroundImage: `url(${imagesPng[index]})` }}>
                                        {/* <span>Slide {index + 1}</span> */}
                                    </div>
                                </div>
                            );
                        })
                }
            </Slide>
        </div>
    );
}

export default GaleriaFotos;
