function Localizacao() {
        return (
        <div className="max-w-7xl mx-auto pb-10 localizacao" id="localizacao">
            <p className="text-secondary text-3xl pt-32 mb-12 text-center">
            LOCALIZAÇÃO PRIVILEGIADA
            </p>
            <p className="text-white text-xl py-3 text-center">
                10 minutos do Catuaí Shopping Cascavel
            </p>
            <p className="text-white text-xl py-3 text-center">
                2 minutos da avenida Piquiri
            </p>
            <p className="text-white text-xl py-3 text-center">
                7 minutos da av. Barão do Rio Branco
            </p>
            <p className="text-white text-xl py-3 text-center">
                7 minutos do Portí Atacadista
            </p>
            <p className="text-white text-xl py-3 text-center">
                7 minutos do Trevo da Ceasa
            </p>
        </div>
    );
}

export default Localizacao;
